.background {
  background: #041527 center no-repeat;
  background-size: cover;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.container {
  padding: 5rem 2rem;
  margin: auto;
  max-width: 90rem;
}

.jumbo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.slogan {
  color: #ffffff;
  transform: translateY(-2rem);
}

.slogan > .logo {
  width: 18rem;
  margin-bottom: 4rem;
}

.slogan > .logo > img {
  width: 100%;
}

.slogan > .primary {
  margin-bottom: 1rem;
  font-size: 3rem;
}

.slogan > .primary > .sub {
  font-size: 2rem;
}

.slogan > .primary > .strong {
  font-weight: bold;
}

.slogan > .secondary {
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.25rem;
  line-height: 1.5;
}

.login {
  width: 25rem;
  padding: 3rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.8);
}

.login > .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.login > .welcome {
  margin-bottom: 4rem;
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, .5); */
}

.login > .welcome a {
  /* padding: 0 .25rem; */
  color: #1b9c85;
  text-decoration: none;
}

.login > .welcome a:hover {
  color: #60c7ae;
}

.title > .sub {
  color: #1b9c85;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.title > h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.cards {
  display: flex;
  margin: 0 -2rem;
}

.cards > .cardItem {
  padding: 2rem;
}

.cards > .cardItem > .thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.cards > .cardItem > .thumb > img {
  width: 50%;
  height: auto;
}

.cards > .cardItem > .title {
  font-size: 1.75rem;
}

.cards > .cardItem > .description {
  margin-top: 2rem;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.5);
}

.cards > .cardItem > .action {
  margin-top: 1rem;
}

.footer {
  padding: 2rem 0;
  background-color: #041527;
  color: #ffffff;
}

.footer a {
  color: #60c7ae;
  text-decoration: none;
}
