.root {
  margin: 0 auto;
  max-width: 64rem;
}

.header {
  display: flex;
  padding: 2rem 2rem 2rem 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.cover {
  padding: 1rem;
  margin-right: 2rem;
  width: 40%;
}

.cover > img {
  display: block;
  width: 100%;
  height: auto;
}

.meta {
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem;
  height: 7rem;
}

.meta > p {
  margin-bottom: 0.25rem;
}

.content {
  flex: 1;
}

.highlights {
  position: relative;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  background: #fafafa;
  border-radius: 0.5rem;
  /* color: #1B9C85; */
}

.highlights > .stamp {
  position: absolute;
  top: -4rem;
  right: -2rem;
  width: 12rem;
  height: 12rem;
  background-image: url("../../assets/bg-certificated.png");
  background-size: 100% auto;
}

.highlights > .item {
  line-height: 2;
}

.highlights > .item > :global(.anticon) {
  margin-right: 0.5rem;
}

.actions {
  margin-top: 2rem;
}

.body {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.illustration {
  width: 100%;
  /* background-color: #ccc; */
  display: flex;
  justify-content: center;
}
