.header {
  position: sticky;
  z-index: 1200;
  top: 0;
}

.body {
  padding: 1rem;
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
}
