.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}

.wrapper {
  width: 20rem;
  transform: translateY(-10vh);
}

.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.logo {
  width: 6rem;
  height: auto;
}

.head > h1 {
  margin: .5rem 0 .5rem 0;
  font-size: 1.25rem;
  font-weight: 500;;
}

.foot {
  margin-top: 4rem;
  font-size: .75rem;
  color: #666666;
  text-align: center;
}