.timestamp {
  margin-bottom: .5rem;
  color: rgba(0, 0, 0, .25);
  text-align: right;
}

.section {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .5rem;
  overflow: hidden;
}

.statistic {
  display: flex;
  align-items: center;
}

.statistic > .item {
  padding: 1rem 3rem;
}

.statistic > .item > :global(.ant-statistic) {
  margin-bottom: 1rem;
}

.statistic > .divider {
  width: 1px;
  height: 6rem;
  background-color: rgba(0, 0, 0, .1);
}

.chart {
  padding: 1rem;
}

.chart > h2 {
  font-size: 1rem;
}