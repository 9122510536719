.root {
  padding-top: .5rem;
}

.alert {
  margin-bottom: 1.5rem;
}

.card {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #F2F2F2;
  border-radius: .5rem;
}

.card > .header {
  margin-right: 1rem;
}

.card > .header > img {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  object-fit: cover;
}

.card > .body {
  flex: 1;
}

.card > .body > .primary {
  font-size: 1.25rem;
  font-weight: 500;
}

.card > .body > .secondary {
  font-size: .75rem;
  color: rgba(0, 0, 0, .5);
}

.consultant {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  background-color: #F2F2F2;
  border-radius: .5rem;
}

.consultant > .header {
  margin-bottom: 1rem;
}
.consultant > .header > img {
  display: block;
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 3rem;
}

.consultant > .body {
  text-align: center;
}

.consultant .row {
  margin-bottom: 1rem;
}

.consultant .row:last-child {
  margin-bottom: 0;
}

.consultant .row > .primary {
  font-size: 1rem;
  font-weight: 500;
}

.consultant .row > .secondary {
  color: rgba(0, 0, 0, .5);
}

.consultant .qrcode {
  display: inline-block;
  margin-top: .25rem;
  width: 9rem;
  height: auto;
  border-radius: .5rem;
}