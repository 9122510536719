.root {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.logo > img {
  display: inline-block;
  height: 2.5rem;
  margin: auto;
}
