.root {
  padding: 3rem 1rem 6rem 1rem;
  margin: 0 auto;
  max-width: 90rem;
  width: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.statistics {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
}

.items {
  padding: 0 1rem;
}
