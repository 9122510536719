.addMember {
    display: flex;
    justify-content: space-between;
}

.modalTitle {
    margin-top: 1rem;
}

.buttons{
    display: inline-flex;
}