.timestamp {
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.25);
  text-align: right;
}

.actions {
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.content {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.descriptions {
  margin-top: 1rem;
}

.descriptions > :global(.ant-descriptions-view) {
  background-color: #fff;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.title {
  margin-top: 1.5rem;
}

.title > :global(.anticon) {
  margin-right: 0.25rem;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.toolbar > .title {
  margin-top: 0;
  transform: translateY(0.5rem);
}

.statistic {
  display: flex;
  align-items: center;
}

.statistic > .item {
  padding: 1rem 3rem;
}

.statistic > .divider {
  width: 1px;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.attachments {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 0.5rem;
}

.attachments > a {
  display: inline-block;
  white-space: nowrap;
}

.attachments > a::after {
  content: "、";
}

.attachments > a:last-child:after {
  content: none;
}
