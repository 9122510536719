.background {
  background: #041527 center no-repeat;
  background-size: cover;
}

.container {
  padding: 20rem 2rem;
  margin: auto;
  max-width: 90rem;
  min-height: 100vh;
}

.jumbo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}

.login {
  width: 25rem;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.8);
}

.login > .title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.login > .welcome {
  margin-bottom: 4rem;
  font-size: 0.875rem;
  /* color: rgba(0, 0, 0, .5); */
}

.login > .welcome a {
  /* padding: 0 .25rem; */
  color: #1b9c85;
  text-decoration: none;
}

.login > .welcome a:hover {
  color: #60c7ae;
}
