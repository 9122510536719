.timestamp {
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.25);
  text-align: right;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
}

.descriptions {
  margin-top: 1rem;
}

.descriptions > :global(.ant-descriptions-view) {
  background-color: #fff;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.title {
  margin-top: 1.5rem;
}

.title > :global(.anticon) {
  margin-right: 0.25rem;
}

.timelineItem > .secondary {
  margin-bottom: 0;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}