.background {
  background: #041527 center no-repeat;
  background-size: cover;
}

.container {
  padding: 5rem 2rem;
  margin: auto;
  max-width: 90rem;
}

.title > .sub {
  color: #1b9c85;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.title > h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
}

.cards {
  display: flex;
  margin: 0 -2rem;
}

.cards > .cardItem {
  padding: 2rem;
}

.cards > .cardItem > .thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
}

.cards > .cardItem > .thumb > img {
  width: 50%;
  height: auto;
}

.cards > .cardItem > .title {
  font-size: 1.75rem;
}

.cards > .cardItem > .description {
  margin-top: 2rem;
  line-height: 1.75;
  color: rgba(0, 0, 0, 0.5);
}

.cards > .cardItem > .action {
  margin-top: 1rem;
}
